import React, { useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { OrganisationInvitationFields } from "./OrganisationInvitationFields.models";
import { Button, Typography } from "@safetyculture/sc-web-ui";
import { InputWithValidation } from "../../../components/form/InputWithValidation";
import { InvitationPrefill } from "../../../services/invitation/invitation.service";
import { FormRow, InviteFormGrid } from "../styles.css";
import { validation_rules } from "../../../components/form/validation_rules";

type Props = {
  invite_prefill?: InvitationPrefill | null;
  onSubmit: (fields: OrganisationInvitationFields) => void;
};

export const OrgInviteForm: React.FC<Props> = ({
  onSubmit,
  invite_prefill,
}) => {
  const form = useForm<OrganisationInvitationFields>({
    defaultValues: useMemo(
      () => ({
        firstname: invite_prefill?.fields.firstname || "",
        lastname: invite_prefill?.fields.lastname || "",
        email: invite_prefill?.fields.email || "",
      }),
      [invite_prefill],
    ),
    mode: "all",
  });

  const submitForm = async () => {
    const is_valid = await form.trigger();
    if (is_valid) {
      onSubmit(form.getValues());
    } else {
      // focus first input with an error
      const form_errors = Object.values(form.formState.errors);
      if (form_errors.length > 0) {
        (form_errors[0].ref as any)?.focus();
      }
    }
  };

  return (
    <FormProvider {...form}>
      <Typography variant="titleLarge">Broker details</Typography>
      <InviteFormGrid>
        <FormRow>
          <div className="column">
            <InputWithValidation
              name="firstname"
              rules={{
                required: { value: true, message: "First name is required" },
              }}
              label="First name"
              placeholder="First name"
              control={form.control}
            />
          </div>
          <div className="column">
            <InputWithValidation
              name="lastname"
              rules={{
                required: { value: true, message: "Last name is required" },
              }}
              label="Last name"
              placeholder="Last name"
              control={form.control}
            />
          </div>
        </FormRow>
        <InputWithValidation
          name="email"
          rules={{
            required: { value: true, message: "Email address is required" },
            pattern: {
              value: validation_rules.patterns.email,
              message: "Invalid email address",
            },
          }}
          label="Email"
          placeholder="Email address"
          control={form.control}
          margin={{ top: 24, bottom: 24 }}
        />
      </InviteFormGrid>

      <InviteFormGrid>
        <Button
          type="button"
          onClick={submitForm}
          style={{ marginTop: 32 }}
          variation="primary"
        >
          Submit
        </Button>
      </InviteFormGrid>
    </FormProvider>
  );
};
