import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const TableCell = ({
  children,
  collapse,
  expand,
  textOverflow,
  justifyContent,
  ...otherProps
}: TableCellProps & { $minWidth?: string }) => {
  return (
    <Styled.TableCell
      element="td"
      $collapse={collapse}
      $expand={expand}
      $textOverflow={textOverflow}
      $justifyContent={justifyContent}
      {...otherProps}
    >
      {children && children}
    </Styled.TableCell>
  );
};

interface TableCellProps extends ContainerProps {
  expand?: boolean;
  collapse?: boolean;
  colspan?: string;
  textOverflow?: boolean;
  justifyContent?:
    | "normal"
    | "center"
    | "start"
    | "end"
    | "left"
    | "right"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch";
}

TableCell.defaultProps = {
  collapse: false,
  expand: false,
  textOverflow: false,
};

export default TableCell;
