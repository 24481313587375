import React from "react";
import { ButtonVariant, TButtonType, TStyleConnotation } from "../../../types";
import Link from "../link";
import CircularProgress from "../progress/circular";
import { ButtonBaseProps } from "./buttonBase";
import * as Styled from "./styles.css";

export interface ButtonProps extends ButtonBaseProps {
  color?: TStyleConnotation;
  fullWidth?: boolean;
  router?: boolean;
  href?: string | object;
  isNewTab?: boolean;
  isLoading?: boolean;
  scrollTo?: string;
  value?: string | number;
  variant?: ButtonVariant;
  type?: TButtonType;
  minWidth?: string;
}

const Button = React.forwardRef(
  (
    {
      children,
      color = "surface",
      fullWidth = false,
      router,
      href,
      isLoading = false,
      isNewTab = false,
      scrollTo,
      size = "md",
      type = "button",
      variant = "contained",
      minWidth,
      ...otherProps
    }: ButtonProps,
    ref,
  ) => {
    if (scrollTo) {
      return (
        <Styled.ScrollLink
          to={scrollTo}
          smooth={true}
          offset={-40}
          duration={500}
          containerId="root"
        >
          <Styled.Button
            element="button"
            componentType={type}
            $color={color}
            $size={size}
            $variant={variant}
            $fullWidth={fullWidth}
            $minWidth={minWidth}
            {...otherProps}
          >
            {children && children}
          </Styled.Button>
        </Styled.ScrollLink>
      );
    }
    if (href) {
      return (
        <Link isButton router={router} href={href} isNewTab={isNewTab}>
          <Styled.Button
            element="button"
            componentType={type}
            $color={color}
            $size={size}
            $variant={variant}
            $fullWidth={fullWidth}
            $minWidth={minWidth}
            {...otherProps}
          >
            {children && children}
          </Styled.Button>
        </Link>
      );
    }
    return (
      <Styled.Button
        element="button"
        componentType={type}
        disabled={isLoading}
        ref={ref}
        $color={color}
        $size={size}
        $variant={variant}
        $fullWidth={fullWidth}
        $minWidth={minWidth}
        {...otherProps}
      >
        {children && !isLoading && children}
        {isLoading ? <CircularProgress size={28} /> : ""}
      </Styled.Button>
    );
  },
);

export default Button;
