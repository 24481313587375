import { ContainerProps } from "../container/container";
import { ColumnProps } from ".";
import TableHeader from "./tableHeader";
import TableRow from "./tableRow";
import * as Styled from "./styles.css";

const TableHead = ({ children, columns, ...otherProps }: TableHeadProps) => {
  return (
    <Styled.TableHead element="thead" {...otherProps}>
      <TableRow>
        {columns.map(({ title, minWidth }, i: number) => {
          return (
            <TableHeader key={i} $minWidth={minWidth}>
              {title}
            </TableHeader>
          );
        })}
        {children && children}
      </TableRow>
    </Styled.TableHead>
  );
};

interface TableHeadProps extends ContainerProps {
  columns: ColumnProps[];
}

TableHead.defaultProps = {};

export default TableHead;
