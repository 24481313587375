import { useContext, useEffect, useMemo } from "react";
import { useQuery, useQueryClient, QueryKey } from "react-query";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import useLocalStorageState from "use-local-storage-state";
import { AuthContext } from "../../../contexts";
import {
  CircularProgress,
  Icon,
  Table,
  Typography,
} from "../../../components/util";
import { ColumnProps } from "../../util/table";
import Pagination, {
  createPager,
  createSlice,
} from "../../../components/util/pagination/pagination";
import { ContainerProps } from "../../util/container/container";
import { IMissingProps } from "../missing/missing";
import { IPaginatedResults } from "../../../types";
import { ListMissing } from "..";
import * as Styled from "./styles.css";
import SearchBar from "../forms/search/searchBar";

const PaginatedResults = ({
  id,
  displayMiscButton,
  children,
  searchTerm,
  inputResults,
  service,
  searchService,
  serviceParams,
  missing,
  pageLimits = [25, 50, 100],
  tableColumns,
  tableRows,
  queryKeyPrefixes,
}: IUsersListProps) => {
  const queryClient = useQueryClient();
  const { search } = useLocation();
  const { user } = useContext(AuthContext);

  const [displayLimit, setDisplayLimit] = useLocalStorageState<number>(
    `${user.id}-pagination-${id}`,
    pageLimits[0],
  );

  const urlParams: Record<string, any> = useMemo(
    () => queryString.parse(search),
    [search],
  );

  const fetchEnabled = !!(service || searchService);
  const currentPageNumber: number = parseInt(urlParams.p || "1");

  const queryKeyPrefixesParts = useMemo(
    () =>
      queryKeyPrefixes != null
        ? Array.isArray(queryKeyPrefixes)
          ? queryKeyPrefixes
          : [queryKeyPrefixes]
        : [id],
    [queryKeyPrefixes, id],
  );

  const { isFetching, data: fetchedData } = useQuery<Record<any, any> | null>(
    [
      ...queryKeyPrefixesParts,
      displayLimit,
      currentPageNumber,
      urlParams?.searchTerm,
    ],
    async () => {
      try {
        if (service && !urlParams?.searchTerm) {
          const dataB = await service(
            currentPageNumber - 1,
            displayLimit,
            serviceParams,
          );
          return dataB;
        } else if (searchService && urlParams?.searchTerm) {
          const dataB = await searchService(
            urlParams?.searchTerm,
            currentPageNumber - 1,
            displayLimit,
            serviceParams,
          );
          return dataB;
        } else {
          throw new Error("No service or searchService");
        }
      } catch (error) {
        return null;
      }
    },
    { enabled: fetchEnabled },
  );

  const data = fetchEnabled ? fetchedData : inputResults;

  useEffect(() => {
    fetchEnabled &&
      queryClient.invalidateQueries([
        ...queryKeyPrefixesParts,
        displayLimit,
        currentPageNumber,
      ]);
  }, [
    fetchEnabled,
    currentPageNumber,
    displayLimit,
    queryKeyPrefixesParts,
    queryClient,
  ]);

  const pager = useMemo(
    () => createPager(data?.total, displayLimit, currentPageNumber),
    [data?.total, displayLimit, currentPageNumber],
  );

  const dataSlice = useMemo(() => {
    if (!service) {
      return createSlice(data?.hits, pager);
    } else {
      return data?.hits;
    }
  }, [data?.hits, pager, service]);

  if (isFetching && service && !searchTerm) {
    return <CircularProgress contained />;
  } else if ((data?.hits.length === 0 || !data?.hits) && !searchTerm) {
    return (
      <>
        {displayMiscButton && displayMiscButton(true)}
        <ListMissing
          icon={missing.icon}
          message={missing.message}
          type={missing.type}
        />
      </>
    );
  } else if (data || (isFetching && searchTerm)) {
    return (
      <>
        {displayMiscButton && displayMiscButton(false)}
        {!displayMiscButton && searchService && (
          <Styled.HeaderContainer>
            <Styled.SearchTab>
              <SearchBar searchTerm={searchTerm ? searchTerm : ""} />
            </Styled.SearchTab>
            <Styled.ResultCount styled={{ text: { weight: "heavier" } }}>
              {data?.total || 0} results
            </Styled.ResultCount>
          </Styled.HeaderContainer>
        )}

        <Table columns={tableColumns}>
          {children && children}
          {data && tableRows(dataSlice)}
        </Table>

        {isFetching && searchService && searchTerm && (
          <CircularProgress contained />
        )}
        {!isFetching &&
          (data && data.total ? (
            <Pagination
              pager={pager}
              param="p"
              searchTerm={urlParams?.searchTerm}
              records={data.total}
              pageLimitsAllowed={pageLimits}
              pageLimit={displayLimit}
              onLimitChange={setDisplayLimit}
              prevKey="ArrowLeft"
              nextKey="ArrowRight"
            />
          ) : (
            <Styled.EmptyState>
              <Icon size={"lg"} icon={"search"} />
              <Styled.NoResult shade="stronger" variant="displayMedium">
                No results found
              </Styled.NoResult>
              <Typography shade="weaker">
                Try again with different search terms
              </Typography>
            </Styled.EmptyState>
          ))}
      </>
    );
  } else {
    return <></>;
  }
};

interface IUsersListProps extends ContainerProps {
  inputResults?: IPaginatedResults;
  service?: (page_offset: number, limit: number, dataParams: any) => {};
  searchService?: (
    searchTerm: string,
    offset: number,
    results: number,
    dataParams: any,
  ) => {};
  serviceParams?: any;
  searchTerm?: string;
  id: string;
  displayMiscButton?: any;
  pageLimits?: number[];
  tableColumns: ColumnProps[];
  tableRows: (dataSlice: any[]) => React.ReactNode;
  missing: IMissingProps;
  queryKeyPrefixes?: QueryKey;
}

PaginatedResults.defaultValues = {
  pageLimits: [25, 50, 100],
};

export default PaginatedResults;
