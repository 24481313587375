import { JfsButtonGroup } from "../../../components/util";
import { JfsMarkup } from "../../../components/util/jfsForm/fields";
import { createDateTime } from "../../../helpers";
import { FlowSteppedFormSchema } from "./../../../types";

export enum ClaimContactType {
  Me = "Me",
  Other = "Other",
}

export const claimSchema: FlowSteppedFormSchema = {
  create: {
    schema: {
      title: "Get Started",
      type: "object",
      required: ["claim", "issue"],
      properties: {
        claim: {
          title: "Who is the claim for?",
          description: "",
          type: "object",
          required: ["insured_name", "policy_number"],
          properties: {
            insured_name: {
              title: "Insured name",
              type: "string",
            },
            policy_number: {
              title: "Policy number",
              type: "string",
            },
            who: {
              title: "Who should we speak to about this claim?",
              type: "string",
              enum: ["Me", "Other"],
              default: "Me",
            },
            preferred_contact_method: {
              title: "Preferred contact method",
              type: "string",
              oneOf: [
                { const: "phone", title: "Phone" },
                { const: "email", title: "Email" },
              ],
            },
          },
          dependencies: {
            who: {
              oneOf: [
                {
                  required: ["contact_name", "phone_number", "email"],
                  properties: {
                    who: {
                      enum: [ClaimContactType.Me],
                    },
                    contact_name: {
                      title: "Contact name",
                      type: "string",
                    },
                    phone_number: {
                      title: "Phone number",
                      type: "string",
                      format: "phone-au",
                    },
                    email: {
                      title: "Email address",
                      type: "string",
                      format: "email",
                    },
                  },
                },
                {
                  required: [
                    "contact_name_other",
                    "phone_number_other",
                    "email_other",
                  ],
                  properties: {
                    who: {
                      enum: [ClaimContactType.Other],
                    },
                    contact_name_other: {
                      title: "Contact name",
                      type: "string",
                    },
                    phone_number_other: {
                      title: "Phone number",
                      type: "string",
                      format: "phone-au",
                    },
                    email_other: {
                      title: "Email address",
                      type: "string",
                      format: "email",
                    },
                  },
                },
              ],
            },
          },
        },
        issue: {
          title: "What happened?",
          type: "object",
          required: ["claim_type", "summary"],
          properties: {
            claim_type: {
              title: "Select the type of claim",
              type: "string",
              oneOf: [
                { title: "Accidental damage", const: "AccidentalDamage" },
                { title: "Accidental loss", const: "AccidentalLoss" },
                { title: "Breakdown", const: "Breakdown" },
                { title: "Burglary / Theft", const: "BurglaryTheft" },
                { title: "Bushfire", const: "Bushfire" },
                {
                  title: "Business income only (e.g. access / evacuation)",
                  const: "BusinessIncomeOnly",
                },
                { title: "Escape of liquid", const: "EscapeOfLiquid" },
                { title: "Fire", const: "Fire" },
                { title: "Glass", const: "Glass" },
                { title: "Impact damage", const: "ImpactDamage" },
                { title: "Liability", const: "Liability" },
                { title: "Lightning", const: "Lightning" },
                { title: "Malicious damage", const: "MaliciousDamage" },
                {
                  title: "Storm / Cyclone / Flood / Hail",
                  const: "StormCycloneFloodHail",
                },
                { title: "Other", const: "Other" },
              ],
            },
            summary: {
              title: "Give us a brief summary of the incident",
              type: "string",
            },
          },
        },
      },
    },
    uiSchema: {
      claim: {
        "ui:hideError": true,
        insured_name: {
          "ui:hideError": false,
        },
        policy_number: {
          "ui:hideError": false,
        },
        who: {
          "ui:widget": JfsButtonGroup,
          "ui:hideError": true,
        },
        contact_name: {
          "ui:hideError": false,
        },
        phone_number: {
          "ui:hideError": false,
        },
        email: {
          "ui:hideError": false,
        },
        contact_name_other: {
          "ui:hideError": false,
        },
        phone_number_other: {
          "ui:hideError": false,
        },
        email_other: {
          "ui:hideError": false,
        },
        preferred_contact_method: {
          "ui:widget": "radio",
          "ui:hideError": false,
        },
        "ui:order": [
          "insured_name",
          "policy_number",
          "who",
          "contact_name",
          "phone_number",
          "email",
          "contact_name_other",
          "phone_number_other",
          "email_other",
          "preferred_contact_method",
        ],
      },
      summary: {
        summary: {
          "ui:widget": "textarea",
        },
      },
    },
    uiPrefillSchema: {
      claim: {
        insured_name: {
          "ui:disabled": true,
        },
        policy_number: {
          "ui:disabled": true,
        },
        contact_name: {
          "ui:disabled": true,
        },
        phone_number: {
          "ui:disabled": true,
        },
        email: {
          "ui:disabled": true,
        },
      },
    },
  },
  details: {
    schema: {
      title: "About the claim",
      type: "object",
      required: ["incident_date"],
      properties: {
        incident_date: {
          title: "When did the incident occur?",
          type: "string",
          format: "date-time",
          formatMaximum: createDateTime("today", "end"),
        },
        officials_involved: {
          title: "Select any officials that were involved",
          type: "array",
          items: {
            type: "string",
            anyOf: [
              {
                const: "police",
                title: "Police",
              },
              {
                const: "firefighters",
                title: "Firefighters",
              },
              {
                const: "ses",
                title: "Volunteer emergency services (SES)",
              },
              {
                const: "ambulance",
                title: "Ambulance",
              },
              {
                const: "utility_company",
                title: "Electrical, water or gas company",
              },
              {
                const: "none",
                title: "None",
              },
              {
                const: "other",
                title: "Other",
              },
            ],
          },
          uniqueItems: true,
        },
        what: {
          title: "What was affected?",
          type: "object",
          required: ["affected_items"],
          properties: {
            affected_items: {
              title: "Describe the items that have been affected",
              type: "string",
            },
            estimated_loss: {
              title: "How much is the estimated loss?",
              type: "number",
              format: "dollar",
              minimum: 0.01,
            },
          },
        },
        who: {
          title: "Who was affected?",
          type: "object",
          properties: {
            how: {
              title: "Tell us more about how they were affected",
              type: "string",
            },
          },
        },
        special_instructions: {
          title: "Special Instructions",
          type: "object",
          properties: {
            anything_else: {
              title: "Is there anything else we should know about?",
              type: "string",
            },
          },
        },
        supporting_documents: {
          title: "Supporting Documents",
          type: "object",
          properties: {
            instructions: {
              type: "string",
              description: `Please send any documents related to this claim to: <a href='mailto:${process.env.REACT_APP_CARE_CLAIMS_EMAIL}' target='_blank'>${process.env.REACT_APP_CARE_CLAIMS_EMAIL}</a>`,
            },
          },
        },
      },
    },
    uiSchema: {
      "ui:submitButtonOptions": {
        submitText: "Continue",
      },
      incident_date: {
        "ui:options": {
          errorLabel: "Incident date",
        },
      },
      what: {
        affected_items: {
          "ui:widget": "textarea",
          "ui:options": {
            errorLabel: "Affected loss",
          },
        },
        estimated_loss: {
          "ui:options": {
            errorLabel: "Estimated loss",
          },
        },
      },
      who: {
        how: {
          "ui:widget": "textarea",
        },
      },
      special_instructions: {
        anythingElse: {
          "ui:widget": "textarea",
        },
      },
      supporting_documents: {
        instructions: {
          "ui:field": JfsMarkup,
        },
      },
    },
  },
  declarations: {
    schema: {
      title: "Please review the following",
      type: "object",
      required: ["charges_and_history", "declarations"],
      properties: {
        charges_and_history: {
          title: "Charge and insurance history",
          description: "",
          type: "object",
          required: ["refused_cancelled", "criminal_offences"],
          properties: {
            refused_cancelled: {
              title:
                "Has any insurer refused or cancelled cover or required special terms to insure your client?",
              type: "string",
              enum: ["Yes", "No"],
            },
            criminal_offences: {
              title:
                "Has your client been charged with, or convicted of, any criminal offence in the last 10 years?",
              type: "string",
              enum: ["Yes", "No"],
            },
          },
        },
        declarations: {
          title: "Declarations",
          description: "",
          type: "object",
          required: ["privacy_consent", "truth", "refusability"],
          properties: {
            privacy_consent: {
              title:
                "I acknowledge that I have read and agree to the <a href='https://care.safetyculture.com/privacy-policy/' target='_blank'>privacy consent and authorisation</a>.",
              type: "boolean",
              enum: [true],
              default: false,
            },
            truth: {
              title:
                "I declare all the above details are true in every respect to the best of my knowledge.",
              type: "boolean",
              enum: [true],
              default: false,
            },
            refusability: {
              title:
                "I acknowledge that a claim may be refused and/or the policy may be cancelled if that answers or information provided is untrue, inaccurate, or concealed.",
              type: "boolean",
              enum: [true],
              default: false,
            },
          },
        },
        linked_inspection_section: {
          title: "Linked Inspection",
          description: "",
          type: "object",
          properties: {
            linked_inspection: {
              title: "Create linked Inspection in the SafetyCulture App",
              type: "boolean",
              default: true,
            },
          },
        },
      },
    },
    uiSchema: {
      linked_inspection_section: {
        "ui:disabled": true,
      },
      charges_and_history: {
        refused_cancelled: {
          "ui:widget": JfsButtonGroup,
        },
        criminal_offences: {
          "ui:widget": JfsButtonGroup,
        },
      },
    },
  },
  summary: {
    markupSchema: {
      title: "Here is what you've told us",
      sections: {
        Policy: {
          Organisation: {
            columns: 2,
            data: "create.claim.insured_name",
          },
          "Policy Number": {
            columns: 2,
            data: "create.claim.policy_number",
          },
        },
        Contact: {
          Contact: {
            columns: 2,
            data: "create.claim.contact_name",
            if: {
              LHS: "create.claim.who",
              RHS: "Other",
              then: "create.claim.contact_name_other",
            },
          },
          Email: {
            columns: 2,
            data: "create.claim.email",
            format: "email",
            if: {
              LHS: "create.claim.who",
              RHS: "Other",
              then: "create.claim.email_other",
            },
          },
          Phone: {
            columns: 2,
            data: "create.claim.phone_number",
            if: {
              LHS: "create.claim.who",
              RHS: "Other",
              then: "create.claim.phone_number_other",
            },
          },
          "Contact method": {
            columns: 2,
            data: "create.claim.preferred_contact_method",
          },
        },
        "Claim details": {
          "Incident date": {
            columns: 2,
            data: "details.incident_date",
            format: "datetime",
          },
          "Claim type": {
            columns: 2,
            data: "create.issue.claim_type",
          },
          "Officials involved": {
            columns: 1,
            data: "details.officials_involved",
            type: "array",
          },
          "Incident summary": {
            columns: 1,
            data: "create.issue.summary",
          },
          "Items affected": {
            columns: 1,
            data: "details.what.affected_items",
          },
          "Estimated loss": {
            columns: 1,
            data: "details.what.estimated_loss",
          },
          "People affected": {
            columns: 1,
            data: "details.who.how",
          },
          "Is Inpection Linked": {
            columns: 1,
            data: "declarations.linked_inspection_section.linked_inspection",
            format: "boolean",
          },
        },
      },
    },
  },
};
