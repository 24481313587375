import styled from "styled-components";

export const Spacing = styled.div<{
  $width?: number;
  $height?: number;
}>`
  // vertical
  width: ${(props: { $width?: number }) => props.$width || 0}px;
  height: ${(props: { $height?: number }) => props.$height || 0}px;
`;
