import axios from "../../axios";

const ActivityService = {
  async createUserActivity(activity: Activity): Promise<Boolean> {
    try {
      await axios.post("/activity/create", {
        type: activity.type || "",
        action: activity.action || "",
        date: activity.date || Date.now(),
      });
      return true;
    } catch (err) {
      throw new Error("Unable to record Activity");
    }
  },

  async getAll(offset: number, limit: number): Promise<Activity[]> {
    try {
      let params = new URLSearchParams({
        page_number: `${offset}`,
        limit: `${limit}`,
      });
      const res = await axios.get<Activity[]>("/activity/all", { params });
      return res.data;
    } catch (err) {
      throw new Error("Unable to fetch Activity for policy");
    }
  },

  async search(
    searchTerm: string,
    offset: number,
    limit: number,
  ): Promise<Activity[]> {
    try {
      let params = new URLSearchParams({
        searchTerm,
        page_number: `${offset}`,
        limit: `${limit}`,
      });
      const res = await axios.get<Activity[]>("/activity/all/search", {
        params,
      });
      return res.data;
    } catch (err) {
      throw new Error("Unable to fetch Activity for policy");
    }
  },

  async getActivityByPolicyId(policy_id: String): Promise<Activity[]> {
    try {
      const res = await axios.get<Activity[]>(`/activity/policy/${policy_id}`);
      return res.data;
    } catch (err) {
      throw new Error("Unable to fetch Activity for policy");
    }
  },

  async getActivityByUserId(user_id: String | undefined): Promise<Activity[]> {
    try {
      const res = await axios.get<Activity[]>(`/activity/user/${user_id}`);
      return res.data;
    } catch (err) {
      throw new Error("Unable to fetch Activity for user");
    }
  },
};

export interface Activity {
  type: ActivityTypes;
  user?: string;
  action?: ActivityActions;
  date?: string;
  data?: any;
  user_email?: string;
}

export type ActivityTypes =
  | "Account"
  | "Email verified"
  | "iAuditor"
  | "iAuditor Inspection"
  | "Onboarding"
  | "Policy creation date"
  | "Self Assessment"
  | "Virtual Survey"
  | "Self assessment"
  | "Virtual survey";

export type ActivityActions =
  | "Booked"
  | "Completed"
  | "Connected"
  | "Created"
  | "New Client Experience dismissed"
  | "Started"
  | "Verified"
  | "Visited";

export default ActivityService;
