import { useEffect } from "react";

/**
 * useAsyncEffect in its simplest form.
 * Does not handle clean up, go for a standard useEffect instead if needed.
 * @param asyncFunction Async effect
 * @param watchers dependency array
 */
export const useAsyncEffect = (
  asyncFunction: () => Promise<void>,
  watchers: any[],
) => {
  useEffect(() => {
    const asyncEffect = async () => {
      await asyncFunction();
    };
    asyncEffect();
  }, watchers); // eslint-disable-line react-hooks/exhaustive-deps
};
