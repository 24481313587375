import { useHistory } from "react-router-dom";
import { Header, Section } from "../../components/layout";
import CreateButton from "./create/createButton";
import OrganisationsList from "./OrganisationsList";
import * as Styled from "./styles.css";

const Organisations = () => {
  const history = useHistory();
  const buttonAction = (): any => {
    history.push({
      pathname: `/organisation/create`,
    });
  };
  return (
    <Styled.Grid $fullHeight={true}>
      <Header
        title="Organisations"
        titleIcon="organization"
        type="article"
        actions={
          <CreateButton
            color={"accent"}
            onClick={() => buttonAction()}
            variant={"contained"}
            text="Create Organisation"
          />
        }
      />
      <Section>
        <OrganisationsList />
      </Section>
    </Styled.Grid>
  );
};

export default Organisations;
