import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

const TableHeader = ({ children, ...otherProps }: TableHeaderProps) => {
  return (
    <Styled.TableHeader element="th" {...otherProps}>
      {children && children}
    </Styled.TableHeader>
  );
};

interface TableHeaderProps extends ContainerProps {
  $minWidth?: number;
}

TableHeader.defaultProps = {};

export default TableHeader;
