import { useQuery, UseQueryResult } from "react-query";
import { IPolicy } from "../../types";
import PolicyService from "../../services/policy/policy.service";

export const usePolicyQuery = (
  policy_id: string | null,
): UseQueryResult<IPolicy | null | undefined> => {
  return useQuery<IPolicy | null | undefined>(
    ["use-policy-query", policy_id],
    async () => {
      return policy_id ? PolicyService.get_policy_by_id(policy_id) : null;
    },
  );
};
