/**
 * Reusable validation rules
 */

import { IOption } from "@safetyculture/sc-web-ui/cjs/packages/react/select/types";
import { ValidateResult } from "react-hook-form";

export const validation_rules = {
  // patterns
  patterns: {
    email: /^[^\s@]+@[^\s@]+[\w-]{2,4}$/,
  },

  /**
   * Custom validate rules for use with sc-web-ui select component
   *
   * @param option type string required to interface with reack-hook-form.
   * These rules are for use with SelectWithValidation and IOption only.
   * For string input use react-hook-form built-in validation
   **/
  select: {
    required:
      (message?: string) =>
      (option: IOption | IOption[] | undefined): ValidateResult => {
        if (option && Object.prototype.hasOwnProperty.call(option, "value")) {
          return (option as IOption).value
            ? true
            : message || "This field is required";
        } else if (
          option &&
          Object.prototype.hasOwnProperty.call(option, "length") &&
          ((option as IOption[]).length > 1 || // there is several options selected
            ((option as IOption[]).length > 0 && // only 1 object in the array could still be undefined
              !!(option as IOption[])[0].value))
        ) {
          return true;
        }
        return message || "This field is required";
      },
    pattern:
      (pattern: string | RegExp, message: string) =>
      (option: IOption | IOption[] | undefined): ValidateResult => {
        if (option) {
          let options: IOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as IOption]
            : (option as IOption[]);
          for (let i = 0; i < options.length; i++) {
            if (options[i].value?.match(pattern) === null) {
              return message;
            }
          }
        }
        return true;
      },
    maxLength:
      (maxLength: number, message?: string) =>
      (option: IOption | IOption[] | undefined): ValidateResult => {
        if (option) {
          let options: IOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as IOption]
            : (option as IOption[]);
          for (let i = 0; i < options.length; i++) {
            if (options[i].value.length > maxLength) {
              return message || `Maximum length: ${maxLength}`;
            }
          }
          return true;
        }
        return message || `Maximum length: ${maxLength}`;
      },
    minLength:
      (minLength: number, message?: string) =>
      (option: IOption | IOption[] | undefined): ValidateResult => {
        if (option) {
          let options: IOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as IOption]
            : (option as IOption[]);
          for (let i = 0; i < options.length; i++) {
            if (options[i].value.length < minLength) {
              return message || `Minimum length: ${minLength}`;
            }
          }
          return true;
        }
        return message || `Minimum length: ${minLength}`;
      },
    minQty:
      (minQty: number, message?: string) =>
      (option: IOption | IOption[] | undefined): ValidateResult => {
        if (option) {
          let options: IOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as IOption]
            : (option as IOption[]);
          return options.length > minQty
            ? message || `Select at least ${minQty} options`
            : true;
        }
        return message || `Select at least ${minQty} options`;
      },
    maxQty:
      (maxQty: number, message?: string) =>
      (option: IOption | IOption[] | undefined): ValidateResult => {
        if (option) {
          let options: IOption[] = Object.prototype.hasOwnProperty.call(
            option,
            "value",
          )
            ? [option as IOption]
            : (option as IOption[]);
          return options.length > maxQty
            ? message || `${maxQty} options maximum`
            : true;
        }
        return message || `${maxQty} options maximum`;
      },
  },
};

export type Select_Validation_Rule = keyof typeof validation_rules.select;
