import styled from "styled-components";
import { transparentize } from "polished";
import { theme } from "../../../styles/themes";
import { ColumnProps } from ".";
import { Marker } from "../marker/styles.css";
import Container from "../container/container";

const setColumns = (columns: ColumnProps[]): string => {
  var cssGridColumns: string = "";
  columns.forEach((prop: any, i: number) => {
    if (prop.expand) {
      cssGridColumns += "1fr ";
    } else if (prop.collapse) {
      cssGridColumns += "min-content ";
    } else {
      cssGridColumns += "1fr ";
    }
  });
  if (cssGridColumns === "") {
    cssGridColumns = "1fr";
  }
  return cssGridColumns;
};

export const Table = styled(Container).attrs((props: any) => ({
  $columns: props.$columns,
  $orientation: props.$orientation,
}))`
  border: 1px solid ${theme.color.surface.border.weak.default};
  border-radius: 6px;
  border-spacing: 0;
  color: ${theme.color.surface.text.default.default};
  border-radius: 6px;
  display: grid;
  font-family: ${theme.typography.fontFamily.sans};
  font-size: 16px;
  position: relative;

  ${(props: any) =>
    props.$columns &&
    `
      grid-template-columns: ${setColumns(props.$columns)};
  `};

  ${(props: any) =>
    props.$orientation === "horizontal" &&
    `
    tr:first-of-type {
      td {
        border-top: solid 1px ${transparentize(
          1,
          theme.color.surface.border.weak.default,
        )};
      }
    }
    tr td:first-of-type { background-color: ${
      theme.color.surface.background.weakest.default
    };
    }
  `};
`;

export const TableHead = styled(Container).attrs((props: any) => ({}))`
  display: contents;
  position: relative;
  text-align: left;
  width: 100%;
  th:first-of-type {
    border-radius: ${theme.border.radius.md} 0 0 0;
  }
  th:last-of-type {
    border-radius: 0 ${theme.border.radius.md} 0 0;
  }
`;

export const TableRow = styled(Container).attrs((props: any) => ({}))`
  display: contents;
  position: relative;
  width: 100%;
`;

export const TableRowSubhead = styled(TableRow).attrs((props: any) => ({}))`
  td,
  td:first-of-type {
    background-color: ${theme.color.surface.background.default.default};
  }
`;

export const TableHeader = styled(Container).attrs(
  (props: { $minWidth?: number }) => ({}),
)`
  align-items: center;
  background-color: ${theme.color.surface.background.weakest.default};
  display: flex;
  font-weight: 500;
  height: 48px;
  padding: 0 16px;
  position: relative;
  text-overflow: ellipsis;
  ${(props: { $minWidth?: number }) =>
    props.$minWidth ? `min-width: ${props.$minWidth}px` : ""}
`;

export const TableBody = styled(Container).attrs((props: any) => ({}))`
  display: contents;
  position: relative;
`;

export const TableCell = styled(Container).attrs((props: any) => ({
  $collapse: props.$expand,
  $expand: props.$expand,
  $textOverflow: props.$textOverflow,
  $justifyContent: props.$justifyContent,
  $minWidth: props.$minWidth,
}))`
  min-width: ${({ $minWidth }) => ($minWidth ? $minWidth : "150px")};
  border-top: solid 1px ${theme.color.surface.border.weaker.default};
  display: flex;

  position: relative;
  justify-content: ${({ $justifyContent = "normal" }: any) => $justifyContent};

  ${(props: any) => {
    if (!props.$textOverflow) {
      return `
        white-space: nowrap;
        padding: 0px 16px;
        height: 48px;
        align-items: center;
      `;
    } else {
      return `
        align-items: justify;
        text-align: justify;
        white-space: pre-wrap;
        padding: 12px 16px;
        overflow-wrap: break-word;
        line-height: 1.5;
       `;
    }
  }}
  ${(props: any) => props.$expand && `display: grid;`};
  > span:not(${Marker}),
  a {
    display: block;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
