import { ReactNode } from "react";
import { GridSize } from "@material-ui/core/Grid/Grid";
import { commaSeparated, getObjectByString } from "../../../../helpers";
import {
  ComponentProps,
  JfsFormData,
  TJfsMarkupFormat,
  JfsMarkupSchema,
  TJfsMarkupType,
} from "../../../../types";
import DateTime from "../../dateTime/dateTime";
import { Grid, Typography } from "../../../util";
import JfsFormContainer from "../../../util/jfsForm/templates/jfsFormContainer/jfsFormContainer";
import * as Styled from "./styles.css";

const FlowMarkup = ({
  childAppend,
  formData,
  markupSchema,
  ...otherProps
}: FlowMarkupProps) => {
  return (
    <JfsFormContainer>
      <Typography paragraph variant="displayMedium">
        {markupSchema.title}
      </Typography>
      {Object.keys(markupSchema.sections).map((section: any, i: number) => (
        <Styled.GridContainer container>
          <Grid item xs={12}>
            <Styled.SummarySectionHeader variant="heading">
              {section}
            </Styled.SummarySectionHeader>
          </Grid>
          {Object.keys(markupSchema.sections[section]).map(
            (column: any, i: number) => (
              <Grid
                item
                xs={12}
                sm={
                  (12 /
                    markupSchema.sections[section][column].columns) as GridSize
                }
              >
                {formData && (
                  <>
                    <Typography
                      variant="subHeading"
                      styled={{ text: { shade: "weak" } }}
                    >
                      {column}
                    </Typography>
                    <Typography paragraph>
                      {columnData(formData, markupSchema, section, column)}
                    </Typography>
                  </>
                )}
              </Grid>
            ),
          )}
        </Styled.GridContainer>
      ))}
      {childAppend && childAppend}
    </JfsFormContainer>
  );
};

export interface FlowMarkupProps extends ComponentProps {
  formData?: JfsFormData;
  markupSchema: JfsMarkupSchema;
}

FlowMarkup.defaultProps = {};

export default FlowMarkup;

export const columnData = (formData, markupSchema, section, column) => {
  let val = getObjectByString(
    formData,
    markupSchema.sections[section][column].data,
  )
    ? getObjectByString(formData, markupSchema.sections[section][column].data)
    : "";

  const LHS = markupSchema.sections[section][column].if
    ? getObjectByString(formData, markupSchema.sections[section][column].if.LHS)
    : "";
  if (LHS) {
    const RHS = markupSchema.sections[section][column].if.RHS;
    if (LHS === RHS) {
      val = getObjectByString(
        formData,
        markupSchema.sections[section][column].if.then,
      );
    }
  }

  const handleFormat = (
    data: string,
    format?: TJfsMarkupFormat,
  ): string | ReactNode => {
    if (!format) {
      return (
        <Typography styled={{ text: { case: "sentence" } }}>{data}</Typography>
      );
    }
    switch (format) {
      case "date":
      case "datetime":
        return (
          <DateTime
            input={data}
            outputFormat={format === "date" ? "short" : "verbose"}
          />
        );
      case "boolean":
        return data ? "Yes" : "No";
      case "email":
      default:
        return data;
    }
  };

  const handleType = (
    data?: any,
    type?: TJfsMarkupType,
    format?: TJfsMarkupFormat,
  ): string | ReactNode => {
    if (!data) {
      return <Typography styled={{ text: { shade: "weak" } }}> -</Typography>;
    }
    switch (type) {
      case "array":
        return (
          <Typography styled={{ text: { case: "title" } }}>
            {commaSeparated(data)}
          </Typography>
        );
      case "string":
      default:
        return handleFormat(data, format);
    }
  };

  return handleType(
    val,
    markupSchema.sections[section][column].type,
    markupSchema.sections[section][column].format,
  );
};
