import styled from "styled-components";
import { breakpoints, theme } from "../../styles";

import {
  Button,
  Grid as ComponentGrid,
  Link as ComponentLink,
  IconButton,
  SelectMenu,
  SelectOption,
  Table,
  Typography,
} from "../../components/util";

import { GridProps } from "@material-ui/core";
import { buildBoxShadow } from "../../helpers";

export interface StyledGridProps extends GridProps {
  $fullHeight?: boolean;
}

export const SubHeader = styled(Typography)`
  margin-top: 8px;
  color: ${theme.color.surface.text.strong.default};
  font-weight: normal;
`;

export const Link = styled(ComponentLink)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Grid = styled(ComponentGrid)<StyledGridProps>`
  height: ${props => (props.$fullHeight ? "100%" : "initial")};
`;

export const ResultCount = styled(Typography).attrs(() => ({
  variant: "body1",
}))`
  font-weight: 600;
  margin: ${theme.spacing}px 0;
`;

export const OrgsListTable = styled(Table)`
  th:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
  td:not(:first-of-type) {
    display: none;
    @media ${breakpoints.up("md")} {
      display: flex;
    }
  }
`;

export const MoreOptionSelectMenu = styled(SelectMenu)`
  transform: translate3d(10px, 10px, 0);
  z-index: 99;
  border-radius: 6px;
  box-shadow: ${buildBoxShadow("high")};
`;

export const MoreOptionSelectOption = styled(SelectOption)`
  padding: 8px 15px;
  background-color: ${theme.color.surface.background.default.default};
`;

export const MoreOptionOrgSectionSelectOption = styled(SelectOption)`
  padding: 0px;
  background-color: ${theme.color.surface.background.default.default};
`;

export const MoreOptionIconButton = styled(Button)`
  transform: rotate(90deg);
`;

export const MoreOptionButton = styled(Button)`
  padding-left: 10px;
  padding-right: 80px;
  padding-top: 5px;
  padding-bottom: 5px;
  align-text: left;
`;

export const OrgMoreOptionIconButton = styled(IconButton)`
  transform: rotate(90deg);
`;
export const AddOrgIcon = styled(Typography)`
  padding-right: 1em;
`;
