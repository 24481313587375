import {
  ComponentProps,
  TStyleConnotation,
  TStyleShade,
  TypographyVariant,
} from "../../../types";
import * as Styled from "./styles.css";

const Typography = ({
  childAppend,
  childPrepend,
  children,
  element,
  noWrap,
  paragraph,
  variant,
  styled,
  ...otherProps
}: TypographyProps) => {
  const derivedComponent: keyof HTMLElementTagNameMap =
    element || variantMapping[variant] || "p";

  return (
    <Styled.Typography
      element={derivedComponent}
      paragraph={paragraph}
      variant={variant}
      noWrap={noWrap}
      $paragraph={paragraph}
      $variant={variant}
      styled={styled}
      {...otherProps}
    >
      {children && children}
    </Styled.Typography>
  );
};

export interface TypographyProps extends ComponentProps {
  color?: TStyleConnotation | "inherit";
  name?: string;
  noWrap: boolean;
  paragraph: boolean;
  shade?: TStyleShade;
  variant: TypographyVariant;
  truncate?: boolean;
}

Typography.defaultProps = {
  noWrap: false,
  paragraph: false,
  style: {
    weight: "default",
  },
  variant: "body1",
  truncate: false,
};

const variantMapping: Record<TypographyVariant, any> = {
  displayLarge: "h1",
  displayMedium: "h1",
  displaySmall: "h2",
  heading: "h3",
  subHeading: "h4",
  subtitle1: "h5",
  subtitle2: "h6",
  body1: "p",
  body2: "p",
  small: "p",
};

export type HTMLTags = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";

export default Typography;
