import { useContext, useEffect, useMemo, useState } from "react";
import organisationService from "../../../services/organisation/organisation.service";
import {
  Button,
  Popover,
  TableCell,
  TableRow,
  Typography,
} from "../../../components/util";
import { GuidanceContext } from "../../../contexts";
import { InviteUser, PaginatedResults } from "../../../components/content/";
import { Invitation } from "../../../services/invitation/invitation.service";
import PolicyService from "../../../services/policy/policy.service";
import { IPolicy } from "../../../types";
import { transformPolicyType, invitationStatus } from "../../../helpers";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import * as Styled from "../styles.css";

const ClientList = ({ orgId }: { orgId?: string }) => {
  const { search } = useLocation();
  const urlParams: Record<string, any> = useMemo(
    () => queryString.parse(search),
    [search],
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    urlParams?.searchTerm ? urlParams?.searchTerm : "",
  );

  const serviceParams = { org_id: orgId };

  useEffect(() => {
    setSearchTerm(urlParams?.searchTerm);
  }, [urlParams?.searchTerm]);

  const clientsRow = (clientsSlice: IPolicy[]) => {
    return (
      <>
        {clientsSlice.map((policy: IPolicy, i: number) => {
          const customer_insured_name = (() => {
            if (policy.type === "bpk" || policy.type === "wc") {
              return policy.policy_data.customer_insured_name;
            } else if (policy.type === "cpx") {
              return (
                policy.policy_data?.BUSINESS_NAME ||
                policy.policy_data?.TRADING_NAME ||
                policy.policy_data?.business_name
              );
            } else {
              return "{unknown}";
            }
          })();

          return (
            <TableRow key={i}>
              <TableCell
                expand
                markerPlacement={
                  i === 0 && (newBrokerClientProgress || newBrokerVirtualSurvey)
                    ? "left"
                    : undefined
                }
              >
                <Styled.Link
                  href={`/policies/${policy.id}`}
                  router
                  title={customer_insured_name}
                >
                  {customer_insured_name}
                </Styled.Link>
              </TableCell>
              <TableCell>
                <Typography truncate={true}>
                  {transformPolicyType(policy.type)}
                </Typography>
              </TableCell>
              <TableCell>
                {policy.policy_number ? policy.policy_number : "{unknown}"}
              </TableCell>
              <TableCell>
                <InviteClientButton policy={policy} />
              </TableCell>
              <TableCell>
                <OverflowMenuButton policy={policy} />
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const { newBrokerClientProgress, newBrokerVirtualSurvey } =
    useContext(GuidanceContext);

  return (
    <PaginatedResults
      id={`portfolio-all${orgId ? "-" + orgId : ""}`}
      searchTerm={searchTerm}
      searchService={PolicyService.search_policies}
      service={PolicyService.get_policies_for_user}
      tableColumns={[
        { title: "Client", expand: true },
        { title: "Product type" },
        { title: "Policy number" },
        { title: "Actions", collapse: true },
        { title: "", collapse: true },
      ]}
      serviceParams={serviceParams}
      tableRows={clientsRow}
      missing={{
        icon: "checklist",
        type: "policies",
        message:
          "This is where we'll show all of your client's policies. Create and bind your first quote to get started.",
      }}
      queryKeyPrefixes={["portfolio-all", { orgId }]}
    />
  );
};

export interface ClientInvitation extends Invitation {
  client?: string;
  scheme?: string;
}

const InviteClientButton = ({ policy }: { policy: IPolicy }) => {
  switch (invitationStatus(policy)) {
    case "doNotInvite":
      return (
        <Button variant="contained" disabled>
          Do not invite
        </Button>
      );
    case "invite":
      return (
        <InviteUser
          action="send"
          context="list"
          policyId={policy.id}
          type="client"
        />
      );
    case "invited":
      return (
        <Button variant="contained" disabled>
          Invite sent
        </Button>
      );
    default:
      return null;
  }
};

const OverflowMenuButton = ({ policy }: { policy: IPolicy }) => {
  const hasInvitations = policy?.invitations?.some(
    (invite: Invitation) => invite && invite.status === "active",
  );
  const canViewPolicy = policy.type !== "wc";
  return (
    <Popover
      alignment="end"
      trigger={
        <Styled.MoreOptionIconButton
          disabled={!hasInvitations && !canViewPolicy}
          icon="kebab-horizontal"
        >
          Pop
        </Styled.MoreOptionIconButton>
      }
    >
      <Styled.MoreOptionSelectMenu>
        {hasInvitations && (
          <>
            <InviteUser
              action="resend"
              context="overflow"
              invitation={policy.invitations[0]}
              type="client"
            />
            <InviteUser
              action="cancel"
              context="overflow"
              invitation={policy.invitations[0]}
              type="client"
            />
          </>
        )}
        {canViewPolicy && (
          <Styled.MoreOptionSelectOption>
            <Styled.LinkButton
              onClick={event => {
                event.preventDefault();
                const link = openPolicyLink(
                  policy.policy_id,
                  policy.policy_number || "",
                );
                window.open(link);
              }}
              href={openPolicyLink(
                policy.policy_id,
                policy.policy_number || "",
              )}
            >
              View policy details
              <Styled.MoreOptionLaunchIconButton icon="link-external" />
            </Styled.LinkButton>
          </Styled.MoreOptionSelectOption>
        )}
      </Styled.MoreOptionSelectMenu>
    </Popover>
  );
};

const openPolicyLink = (policy_id: string, policy_number: string) => {
  return organisationService.getPolicyLink(policy_id, policy_number);
};

export default ClientList;
