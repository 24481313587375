import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useFlag } from "@unleash/proxy-client-react";

import { Header, Section } from "../../components/layout";
import { PaginatedResults } from "../../components/content";
import { Button, TableRow, TableCell } from "../../components/util";
import * as Styled from "./styles.css";
import toolkitService, {
  Toolkit,
} from "../../services/toolkits/toolkits.service";
import queryString from "query-string";
import { formatDateTime } from "../../helpers";

const Toolkits = () => {
  const portalToolkitsCreate = useFlag("portal.toolkits.create");
  const { search } = useLocation();
  const urlParams: Record<string, any> = useMemo(
    () => queryString.parse(search),
    [search],
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    urlParams?.searchTerm ? urlParams?.searchTerm : "",
  );

  useEffect(() => {
    setSearchTerm(urlParams?.searchTerm);
  }, [urlParams?.searchTerm]);

  const ToolkitRow = (toolkits: Toolkit[]) => (
    <>
      {toolkits.map(toolkit => (
        <TableRow key={toolkit.id}>
          <TableCell expand>
            <Styled.Link
              href={`/toolkits/${toolkit.id}`}
              router
              title={toolkit.name}
              styled={{ text: { case: "title" } }}
            >
              {toolkit.name}
            </Styled.Link>
          </TableCell>
          <TableCell>{toolkit.code}</TableCell>
          <TableCell>
            {formatDateTime(`${toolkit.created_at}`, "short")}
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  return (
    <Styled.Grid $fullHeight>
      <Header
        title="Toolkits"
        titleIcon="tools"
        titleIconPosition="start"
        type="article"
        actions={
          portalToolkitsCreate && (
            <Button
              color="accent"
              router
              href="/toolkits/create"
              variant="contained"
            >
              Create Toolkit
            </Button>
          )
        }
      />
      <Section>
        <PaginatedResults
          id="toolkits"
          searchTerm={searchTerm}
          searchService={toolkitService.getAll}
          service={(offset, results) =>
            toolkitService.getAll(undefined, offset, results)
          }
          tableColumns={[
            { title: "Name" },
            { title: "Code" },
            { title: "Created date", collapse: true, minWidth: 140 },
          ]}
          tableRows={ToolkitRow}
          missing={{
            icon: "tools",
            type: "toolkits",
            message: "No Toolkits found",
          }}
        />
      </Section>
    </Styled.Grid>
  );
};

export default Toolkits;
