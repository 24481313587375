import styled from "styled-components";
import { Container } from "../../util";
import { breakpoints, theme } from "../../../styles";

export const AppContainer = styled("div")`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  height: 100vh;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const Main = styled(Container).attrs((props: any) => ({
  $isAppFramed: props.$isAppFramed,
  $isDesktop: props.$isDesktop,
  $pageType: props.$pageType,
}))`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: ${(props: any) => {
    switch (props.$pageType) {
      case "flow":
        return `${theme.color.surface.background.weakest.default};`;
      default:
        return `${theme.color.surface.background.default.default};`;
    }
  }};

  ${({ $isDesktop }) =>
    $isDesktop &&
    `
    margin-top: 0px;
    margin-left: 256px;
  `}

  ${(props: any) => {
    if (props.$isAppFramed) {
      return `
        display: flex;
        height: 100vh;
        margin: 0;
        padding: 0;
        align-content: center;
        max-width: 100%;

        article {
          align-content: center;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          justify-content: center;
          max-width: 100%;
          padding: 0;
          width: 100vw;
        }
      `;
    }
  }}
`;

export const Article = styled(Container).attrs((props: any) => ({
  $isAppFramed: props.$isAppFramed,
  $pageType: props.$pageType,
}))`
  flex: 1;
  /* This is done here instead of on Main so the scrollbar is at the far right */
  max-width: 1304px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 24px;
  width: 100%;

  @media ${breakpoints.up("md")} {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 40px;
  }
  @media ${breakpoints.up("xl")} {
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 48px;
  }
  @media ${breakpoints.up("xxl")} {
    max-width: 1464px; /* 1304 + 112 + 48 */
    padding-left: 112px;
  }

  ${(props: any) => {
    switch (props.$pageType) {
      case "flow":
        return `
          position: relative;
          padding-top: 56px;
          @media ${breakpoints.up("md")} {
            padding-top: 56px;
          }
          @media ${breakpoints.up("xl")} {
            padding-top: 56px;
          }
          @media ${breakpoints.up("xxl")} {
            padding-top: 56px;
          }
        `;
      default:
        return ``;
    }
  }};
`;
