import { useContext, useState } from "react";
import { InviteGrid } from "../styles.css";
import { Header, Section } from "../../../components/layout";
import { OrgInviteForm } from "./OrgInviteForm";
import InvitationService, {
  Invitation,
  InvitationPrefill,
} from "../../../services/invitation/invitation.service";
import { useHistory, useLocation } from "react-router-dom";
import { OrganisationInvitationFields } from "./OrganisationInvitationFields.models";
import { useAsyncEffect } from "../../../hooks/useAsyncEffect";
import { Spinner } from "@safetyculture/sc-web-ui";
import { Spacing } from "../../../components/layout/Spacing";
import { EmptyState } from "../../../components/layout/EmptyState";
import { AuthContext } from "../../../contexts";

type Props = {};

export const InviteToOrganisationPage: React.FC<Props> = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const org_id = query.get("id");
  const user_context = useContext(AuthContext);

  const [invite_data_prefill, setInviteDataPrefill] =
    useState<InvitationPrefill | undefined | null>(undefined);

  useAsyncEffect(async () => {
    if (org_id) {
      const invite_prefill = await InvitationService.fetchPrefillForOrg(org_id);
      setInviteDataPrefill(invite_prefill);
    }
  }, [org_id]);

  const handleSubmit = async (fields: OrganisationInvitationFields) => {
    if (org_id) {
      const invite: Pick<
        Invitation,
        | "firstname"
        | "lastname"
        | "email"
        | "role"
        | "sender_org_id"
        | "sender_first_name"
        | "sender_last_name"
      > = {
        email: fields.email,
        firstname: fields.firstname,
        lastname: fields.lastname,
        sender_first_name: user_context.user?.firstname,
        sender_last_name: user_context.user?.lastname,
        role: "broker", // Broker is the only role for organisation invitations
        sender_org_id: org_id,
      };

      const created = await InvitationService.createInvitation(invite);
      if (created) {
        history.push("/invitations");
      }
    }
  };

  return (
    <InviteGrid>
      <Header
        title={`Invite broker to ${invite_data_prefill?.meta?.sender_org_name}`}
        titleIcon="mail"
        titleIconPosition="end"
        type="article"
        withLink={{ path: "/organisations", text: "All Organisations" }}
      />
      <Section>
        {invite_data_prefill === undefined && (
          <>
            <Spacing $height={200} />
            <Spinner />
          </>
        )}
        {!!invite_data_prefill && (
          <>
            <Spacing $height={32} />
            <OrgInviteForm
              onSubmit={handleSubmit}
              invite_prefill={invite_data_prefill}
            />
            <Spacing $height={32} />
          </>
        )}
        {invite_data_prefill === null && (
          <EmptyState title="Wrong link. No organisations found" />
        )}
      </Section>
    </InviteGrid>
  );
};
