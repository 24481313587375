import { EmptyState as BaseEmptyState } from "@safetyculture/sc-web-ui";
import { EmptyStateProps } from "@safetyculture/sc-web-ui/cjs/packages/react/empty-state/types";
import * as React from "react";
import styled from "styled-components";

type Props = EmptyStateProps;

export const EmptyState: React.FC<Props> = ({ title }) => {
  return (
    <Container>
      <BaseEmptyState title={title} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
