import styled from "styled-components";

export const FormSpacing = styled.div<{
  $marginTop?: number;
  $marginBottom?: number;
  $marginLeft?: number;
  $marginRight?: number;
}>`
  width: inherit;
  display: flow;

  // vertical
  margin-top: ${(props: { $marginTop?: number }) => props.$marginTop || 0}px;
  margin-bottom: ${(props: { $marginBottom?: number }) =>
    props.$marginBottom || 0}px;
  // horizontal
  margin-left: ${(props: { $marginLeft?: number }) => props.$marginLeft || 0}px;
  margin-right: ${(props: { $marginRight?: number }) =>
    props.$marginRight || 0}px;
`;
