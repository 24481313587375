import { useQuery } from "react-query";
import { useState } from "react";
import { Header, Section } from "../../../layout";
import {
  Button,
  CircularProgress,
  TableCell,
  TableRow,
  Modal,
  FormRow,
  Typography,
  Link,
} from "../../../util";
import { PaginatedResults } from "../..";
import toolkitService, {
  Toolkit,
} from "../../../../services/toolkits/toolkits.service";
import * as Styled from "./styles.css";

const ToolkitRow = (toolkits: Toolkit[]) => {
  return (
    <>
      {toolkits.map((toolkit: Toolkit, i: number) => (
        <TableRow key={i}>
          <TableCell>
            {<Link href={`/toolkits/${toolkit.id}`}>{toolkit.name}</Link>}
          </TableCell>
          <TableCell>{toolkit.code}</TableCell>
        </TableRow>
      ))}
    </>
  );
};

const AddToolkitModal = ({
  org_id,
  toggleModal,
}: {
  org_id: string;
  toggleModal: () => void;
}) => {
  const [addToolkitData] = useState({
    code: "",
  });

  const [addToolkitErrors] = useState({
    code: false,
  });

  const [isEditable, setIsEditable] = useState(true);

  const addToolkit = async (data: { code: string }) => {
    setIsEditable(false);
    const { code } = data;

    if (!code || code.length === 0) {
      throw new Error("Toolkit code is required.");
    }

    try {
      await toolkitService.addToolkitToOrg(org_id, code);
      setIsEditable(true);
    } catch (error) {
      setIsEditable(true);
      throw error;
    }
  };

  return (
    <>
      <Modal
        isOpen={true}
        onClose={toggleModal}
        headerComponent={
          <Typography variant="displaySmall">Add toolkit</Typography>
        }
        headerDivider
      >
        <Styled.ModalBody>
          <Styled.ModalForm
            fullWidth
            submitAlign="right"
            submitInline
            submitText="Add toolkit"
            validationErrors={[addToolkitErrors]}
            validationFields={[addToolkitData]}
            onSubmit={{
              service: addToolkit,
              onSuccess: toggleModal,
            }}
          >
            <FormRow>
              <Styled.ModalTextField
                editable={isEditable}
                label="Toolkit code"
                id="code"
                type="text"
                value={addToolkitData.code}
                helpText="Only specific codes can be used. Toolkits cannot be removed from a customers account."
              />
            </FormRow>
          </Styled.ModalForm>
        </Styled.ModalBody>
      </Modal>
    </>
  );
};

const Toolkits = ({ orgId }: { orgId: string }) => {
  const { data, isLoading } = useQuery(`toolkits_${orgId}`, async () => {
    return toolkitService.getToolkits({ org_id: orgId });
  });

  const [modalVisible, setModalVisible] = useState(false);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  return (
    <>
      <Section>
        <Header
          title="Toolkits"
          type="section"
          actions={
            <Button onClick={toggleModal}>
              Add a toolkit
              <Styled.PlusIcon icon="plus" size="sm" />
            </Button>
          }
        ></Header>
        {!isLoading && (
          <PaginatedResults
            id={`toolkits-org_id-${orgId}`}
            inputResults={{
              hits: data ? data : [],
              total: data ? data.length : 0,
            }}
            tableColumns={[
              { title: "Name", expand: true },
              { title: "Code", expand: true },
            ]}
            tableRows={ToolkitRow}
            missing={{
              icon: "pencil",
              message: "No toolkits associated to organisation",
              type: "toolkits",
            }}
          />
        )}
        {isLoading && <CircularProgress contained />}
      </Section>
      {modalVisible && (
        <AddToolkitModal org_id={orgId} toggleModal={toggleModal} />
      )}
    </>
  );
};

export default Toolkits;
