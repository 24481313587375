import styled from "styled-components";
import { theme } from "../../../../styles/themes";
import { IconButton as MuiIconButton } from "@material-ui/core";
import Link from "../../../util/link";

export const AppBar = styled("header")<AppBarStyleProps>`
  align-items: center;
  background-color: ${theme.color.surface.background.default.default};
  border-bottom: 1px solid ${theme.color.surface.border.default.default};
  box-shadow: none;
  display: ${({ $isVisible }) => ($isVisible ? `flex` : `none`)};
  flex-direction: row;
  height: ${({ $appBarHeight }) => $appBarHeight}px;
  left: 0;
  position: fixed;
  right: 0;
  text-decoration: center;
  top: 0;
  z-index: 999;
`;

export const Hamburger = styled(MuiIconButton)<HamburgerStyleProps>`
  border-radius: 6px;
  left: 0;
  position: absolute;

  &:hover {
    background-color: transparent;
  }

  & .MuiIconButton-label {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    height: 40px;
    position: relative;
    width: 40px;

    &:hover {
      background-color: ${theme.color.surface.background.default.hover};
    }
    &:active {
      background-color: ${theme.color.surface.background.default.pressed};
    }
  }

  & svg {
    height: 25px;
    position: absolute;
    width: 25px;
    &:first-of-type {
      top: 5px;
    }
    &:last-of-type {
      top: 12px;
    }

    ${({ $isOpen }) =>
      $isOpen &&
      `
        &:last-of-type {
          top: 8px;
        }
      `}
  }
`;

export const Logo = styled(Link)`
  height: 28px;
  margin: 14px auto;
  width: 90px;
  & svg {
    fill: ${theme.color.accent.background.default.pressed};
    height: 28px;
  }
  & span {
    position: absolute;
    right: 14px;
    top: 14px;
  }
`;

interface AppBarStyleProps {
  $isVisible: boolean;
  $appBarHeight: number;
}

interface HamburgerStyleProps {
  $isOpen: boolean;
}
