import * as React from "react";
import { FormLabel, Select, Typography } from "@safetyculture/sc-web-ui";
import { IOption } from "@safetyculture/sc-web-ui/cjs/packages/react/select/types";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  PathValue,
  Validate,
  ValidateResult,
} from "react-hook-form";
import { Select_Validation_Rule } from "./validation_rules";
import { FormSpacing } from "./form.css";

export type SelectWithValidationProps<T extends FieldValues> = {
  name: keyof T; // Only map to first level
  control: Control<T>;
  data?: { value: any; label: string }[];
  label?: string;
  rules?: {
    [key in Select_Validation_Rule]?: (
      value: IOption | IOption[],
    ) => ValidateResult;
  };
  multiple?: boolean;
  addOption?: (option: IOption) => void;
  placeholder?: string;
  /**
   * @searchable enable filtering options and retrieving search termn
   * A boolean value is enough to filter through current options
   * Provide a callback to fetch new options based on search
   */
  searchable?: ((search: string) => void) | boolean;
  /**
   * @default false set to true to display a loading spinner in the select
   */
  isLoading?: boolean;
  /**
   * @default {top: 24}
   */
  margin?: { top?: number; bottom?: number; left?: number; right?: number };
};

/**
 * @SelectWithValidation
 *
 * Validation value format: either a string or a selection object {value:string; label:string;}
 * Default value is set where form is created, under appropriate `name` key in default values
 *
 */

export function SelectWithValidation<T extends FieldValues>({
  name,
  control,
  rules,
  data,
  multiple = false,
  addOption,
  placeholder,
  searchable,
  label,
  margin = { top: 24 },
  isLoading = false,
}: SelectWithValidationProps<T>) {
  const field_name = name as Path<T>;

  const SelectWrapper: React.FC<{
    children: React.ReactNode;
    field: ControllerRenderProps<T, Path<T>>;
  }> = ({ children, field }) => {
    if (multiple) {
      return (
        <Select
          multiple={true}
          defaultValue={field.value || undefined}
          onChange={(options?: IOption[]) => field.onChange(options || null)}
        >
          {children}
        </Select>
      );
    } else {
      return (
        <Select
          defaultValue={field.value || undefined}
          onChange={(option?: IOption) => {
            field.onChange(option || null);
          }}
          preventUnset={rules?.required ? true : false}
        >
          {children}
        </Select>
      );
    }
  };

  const handleSearch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const search = e.target.value;
      if (searchable && typeof searchable === "function") {
        searchable(search || "");
      }
    },
    [searchable],
  );

  return (
    <FormSpacing
      $marginTop={margin.top}
      $marginBottom={margin.bottom}
      $marginLeft={margin.left}
      $marginRight={margin.right}
    >
      <Controller
        name={field_name}
        control={control}
        rules={{ validate: rules as Validate<PathValue<T, Path<T>>, T> }}
        render={({ field, fieldState }) => {
          return (
            <div tabIndex={0} ref={field.ref}>
              <FormLabel htmlFor={`select-${field_name}`} optional={false}>
                <Typography variant="titleSmall">{label}</Typography>
              </FormLabel>
              <FormSpacing
                $marginTop={4}
                $marginBottom={4}
                id={`select-${field_name}`}
              >
                <SelectWrapper field={field}>
                  <Select.Trigger>
                    {!searchable ? (
                      <Select.Trigger.DefaultLayout
                        width="full"
                        id={field_name}
                        placeholder={placeholder}
                        error={!!fieldState.error}
                      />
                    ) : (
                      <Select.Trigger.TypeableLayout
                        width="full"
                        id={field_name}
                        placeholder={placeholder}
                        error={!!fieldState.error}
                        onChange={handleSearch}
                      />
                    )}
                  </Select.Trigger>
                  <Select.Content matchTriggerWidth={true}>
                    {isLoading && <Select.Content.LoadingState />}
                    {data?.map((item, i) => (
                      <Select.Item
                        key={`${field_name}-${i}`}
                        value={item.value}
                        label={item.label}
                      />
                    ))}
                    {!!addOption && (
                      <Select.Content.AddOptionButton
                        onClick={label => {
                          const option = { label, value: label };
                          addOption(option);
                          return option;
                        }}
                      />
                    )}
                    {(!data || !data?.length) && !isLoading && (
                      <Select.Content.EmptyState title="No options available" />
                    )}
                  </Select.Content>
                </SelectWrapper>
                {fieldState.error?.message && (
                  <Typography
                    color="negative.text.default"
                    component="span"
                    variant="bodyMedium"
                  >
                    {fieldState.error.message}
                  </Typography>
                )}
              </FormSpacing>
            </div>
          );
        }}
      />
    </FormSpacing>
  );
}
