import { useContext } from "react";

import { Tab, TabPanel, Tabs } from "../../../components/util";
import VirtualSurveyTab from "./virtualSurveyTab";
import ScoreTab from "./scoreTab";
import InvitationsTab from "./invitationsTab";
import { IPolicy } from "../../../types";
import OrganisationsList from "../../organisations/OrganisationsList";
import SettingsTab from "./settingsTab";
import useLocalStorageState from "use-local-storage-state";
import { AuthContext } from "../../../contexts";
import { invitationStatus } from "../../../helpers";
import { hasAllowedRoles } from "../../../helpers/roles";

const VIRTUAL_SURVEY_CLICK_MAX_COUNT = 1;

const PolicyTabs = ({ policy, policyNumber, children }: iProps) => {
  const { user } = useContext(AuthContext);

  const [virtualSurveyClickCount, setVirtualSurveyClickCount] =
    useLocalStorageState<number>(`${user.id}:virtual-survey-tab-clicks`, 0);

  const isBroker = hasAllowedRoles(user.roles, { allowed: ["broker"] });

  const handleVirtualSurveyClick = (): void => {
    if (!isBroker) return;

    setVirtualSurveyClickCount((state: number): number => {
      let count = typeof state === "number" ? state : 0;

      return count < VIRTUAL_SURVEY_CLICK_MAX_COUNT ? ++count : count;
    });
  };

  return (
    <Tabs
      id={`portfolio-client-${policyNumber}`}
      tabs={[
        <Tab id="overview" label={"Overview"} />,
        <Tab id="score" label={"Score"} />,
        <Tab
          id="virtual-survey"
          markerPlacement={
            isBroker && virtualSurveyClickCount < VIRTUAL_SURVEY_CLICK_MAX_COUNT
              ? "left"
              : undefined
          }
          label="Virtual survey"
          onClick={handleVirtualSurveyClick}
        />,
        <Tab id="invitations" label="Invitations" />,
        <Tab id="org" label="Organisation" />,
      ]}
      tabsRight={[<Tab id="settings" label="Settings" />]}
    >
      <TabPanel id="overview">{children}</TabPanel>
      <TabPanel id="score">
        <ScoreTab policy_id={policy.id} />
      </TabPanel>
      <TabPanel id="virtual-survey" spaced>
        <VirtualSurveyTab policy={policy} />
      </TabPanel>
      <TabPanel id="invitations">
        <InvitationsTab policy={policy} />
      </TabPanel>
      <TabPanel id="org">
        <OrganisationsList policy_id={policy.id} enableEditOption />
      </TabPanel>
      <TabPanel id="settings">
        <SettingsTab
          policyId={policy.id}
          invitationStatus={invitationStatus(policy)}
          settings={{ do_not_invite: policy.do_not_invite }}
        />
      </TabPanel>
    </Tabs>
  );
};

export interface iProps {
  policy: IPolicy;
  policyNumber: string;
  children: JSX.Element;
}

export default PolicyTabs;
