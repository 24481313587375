import { useHistory } from "react-router-dom";
import { ContainerProps } from "../container/container";
import * as Styled from "./styles.css";

export interface LinkProps extends ContainerProps {
  disabled?: boolean;
  router?: boolean;
  href?: string | object | null;
  isButton?: boolean;
  isNewTab: boolean;
  replace?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement>;
  target?: string;
  title?: string;
  disableExternalIcon?: boolean;
}

const Link = ({
  children,
  disabled,
  element,
  href,
  isButton,
  isNewTab,
  replace,
  router,
  target,
  disableExternalIcon,
  ...otherProps
}: LinkProps) => {
  const history = useHistory();
  const hrefMui: string = typeof href === "string" ? href.toString() : "";
  const to = href ?? "#";
  const displayExternalIcon =
    !router && /^https?:\/\//.test(hrefMui) && !disableExternalIcon;

  if (href == null) {
    otherProps.onClick = event => {
      event.preventDefault();
      history.goBack();
    };
  }

  return (
    <>
      {router ? (
        children ? (
          <Styled.InternalLink
            to={to}
            $isButton={isButton}
            role={isButton ? "button" : undefined}
            target={target}
            $styled={{ state: disabled ? "disabled" : undefined }}
            {...otherProps}
          >
            {children}
          </Styled.InternalLink>
        ) : null
      ) : children ? (
        <Styled.ExternalLink
          href={hrefMui}
          $isButton={isButton}
          target={isNewTab ? "_blank" : undefined}
          rel={isNewTab ? "noopener" : undefined}
          role={isButton ? "button" : undefined}
          styled={{ state: disabled ? "disabled" : "default" }}
          {...otherProps}
        >
          {children}
          {displayExternalIcon ? (
            <Styled.Icon
              icon="link-external"
              color="accent"
              size="sm"
              role="presentation"
            />
          ) : null}
        </Styled.ExternalLink>
      ) : null}
    </>
  );
};

Link.defaultProps = {
  isNewTab: false,
};

export default Link;
