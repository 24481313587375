import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { recordAnalyticsEvent } from "../../analytics";
import { DateTime, PaginatedResults } from "../../components/content";
import { Section } from "../../components/layout";
import Header from "../../components/layout/header";
import { TableCell, TableRow, Typography } from "../../components/util";
import { formatDateTime } from "../../helpers";
import ActivityService, {
  Activity,
} from "../../services/activity/activity.service";
import { TStyleConnotation } from "../../types";
import queryString from "query-string";
import * as Styled from "./styles.css";

const ActivityTable = () => {
  const { search } = useLocation();
  const urlParams: Record<string, any> = useMemo(
    () => queryString.parse(search),
    [search],
  );

  const [searchTerm, setSearchTerm] = useState<string>(
    urlParams?.searchTerm ? urlParams?.searchTerm : "",
  );

  useEffect(() => {
    setSearchTerm(urlParams?.searchTerm);
  }, [urlParams?.searchTerm]);

  const activityRow = (activitySlice: Activity[]) => {
    return (
      <>
        {activitySlice.map((activity: Activity, i: number) => (
          <TableRow key={i}>
            <TableCell>{TypeColumn(activity)}</TableCell>
            <TableCell>
              <Typography truncate={true}>{UserColumn(activity)}</Typography>
            </TableCell>
            <TableCell>
              {activity.date && (
                <DateTime input={activity.date} outputFormat="short" />
              )}
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <>
      <Header
        title="Activity"
        titleIcon="pulse"
        titleIconPosition="start"
        type="article"
      />
      <Section>
        <PaginatedResults
          id={`client-activity-all`}
          searchTerm={searchTerm}
          service={ActivityService.getAll}
          searchService={ActivityService.search}
          tableColumns={[
            { title: "Activity type" },
            { title: "User" },
            { title: "Date" },
          ]}
          tableRows={activityRow}
          missing={{
            icon: "pulse",
            type: "activity",
            message:
              "This is where we’ll show all of your client’s activity. Check back later to see their progress.",
          }}
        />
      </Section>
    </>
  );
};

ActivityTable.defaultProps = {};

const sendToAnalytics = (inspectionName: string) => {
  recordAnalyticsEvent("action", "Inspection", {
    action: "inspection_completed",
    name: inspectionName,
  });
};

const TypeColumn = (activity: Activity) => {
  switch (activity.type) {
    case "iAuditor Inspection":
      return (
        <Typography>
          {`Inspection: ${activity?.data?.template?.name}`}
          {TypeColumnAction(activity)}
        </Typography>
      );

    case "Virtual survey":
      return (
        <>
          <Typography element="span">
            {`${activity.type} ${activity.action?.toLowerCase()} `}
            {TypeColumnAction(activity)}
          </Typography>
          <Typography element="span" styled={{ text: { shade: "weak" } }}>
            {`- ${formatDateTime(activity.data?.booking?.date)}`}
            {TypeColumnAction(activity)}
          </Typography>
        </>
      );

    default:
      return (
        <>
          <Typography>
            {`${activity.type} `} {TypeColumnAction(activity)}
          </Typography>{" "}
        </>
      );
  }
};

const TypeColumnAction = (activity: Activity) => {
  const color = (activity: Activity): TStyleConnotation => {
    switch (activity.action) {
      case "New Client Experience dismissed":
        return "warning";
      default:
        return "positive";
    }
  };
  return (
    <Styled.ActionStatus
      label={activity.action}
      size="md"
      color={color(activity)}
    />
  );
};

const UserColumn = (activity: Activity) => {
  switch (activity.type) {
    case "iAuditor Inspection":
      return activity?.data?.author?.name;
    case "Virtual survey":
      return activity.data?.booking?.booked_by
        ? activity.data.booking.booked_by
        : activity?.data?.booking?.name;
    default:
      return activity?.data?.name || activity?.user_email;
  }
};

export default ActivityTable;
export const PAGE_PARAM_NAME = "activityPage";
export const PAGE_LIMITS_ALLOWED = [25, 50, 100];
