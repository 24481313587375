import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useFlag } from "@unleash/proxy-client-react";

import { Header, Section } from "../../components/layout";
import * as Styled from "./styles.css";
import toolkitService from "../../services/toolkits/toolkits.service";

import { Summary } from "../../components/content";
import {
  Button,
  TableRow,
  TableCell,
  CircularProgress,
  Table,
  Typography,
} from "../../components/util";
import { formatDateTime } from "../../helpers";
import { PageNotFound } from "..";

const Toolkit = () => {
  const portalToolkitsCreate = useFlag("portal.toolkits.create");
  const { id, code } = useParams<Params>();

  const { data, isError, isFetching } = useQuery(
    ["toolkit", id ?? code],
    async () => {
      if (id != null) {
        return toolkitService.getById(id);
      } else if (code != null) {
        return toolkitService.getByCode(code);
      }
    },
  );

  if (isError) return <PageNotFound />;

  return (
    <>
      <Styled.Grid $fullHeight={true}>
        {isFetching && (
          <Styled.Grid style={{ textAlign: "center" }}>
            <CircularProgress />
          </Styled.Grid>
        )}
        {!isFetching && data && (
          <>
            <Header
              title={data.name}
              titleIcon="tools"
              titleIconPosition="end"
              type="article"
              withLink={{ path: "/toolkits", text: "All Toolkits" }}
              actions={
                portalToolkitsCreate && (
                  <Button
                    color="surface"
                    router
                    href={`/toolkits/${data.id}/edit`}
                    variant="contained"
                    fullWidth={true}
                    minWidth="118px"
                  >
                    Edit Toolkit
                  </Button>
                )
              }
            />
            <Summary
              data={[
                {
                  Code: data.code || "",
                },
                {
                  Created: formatDateTime(
                    data.created_at.toString(),
                    "verbose",
                  ),
                },
                {
                  Updated: formatDateTime(
                    data.updated_at.toString(),
                    "verbose",
                  ),
                },
              ]}
            />
            <Section>
              <Header title="Templates" type="section" />
              <Table
                columns={[
                  { title: "Name", expand: true },
                  { title: "Template ID", expand: true },
                ]}
              >
                {data.tools.map((tool, i) => {
                  if (Array.isArray(tool.data)) {
                    return tool.data.map((template, j) => {
                      return (
                        <TableRow key={i * j}>
                          <TableCell>
                            <Typography truncate={true}>
                              {template.name ? template.name : "{unknown}"}
                            </Typography>
                          </TableCell>
                          <TableCell>{template.template_id}</TableCell>
                        </TableRow>
                      );
                    });
                  }
                  return <></>;
                })}
              </Table>
            </Section>
          </>
        )}
      </Styled.Grid>
    </>
  );
};

type Params = {
  id?: string;
  code?: string;
};

export default Toolkit;
